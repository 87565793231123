<template>
  <div>

    <!-- Main Row -->
    <div class="row">

      <!-- Form -->
      <div class="col-md-12">
        <Card v-bind:title="'Edit Stok Opname'">
          <template
            v-slot:body
            v-if="dataLoaded"
          >
            <Form
              :form="form"
              :route="'inventory-stock-opname/' + form.id"
              purpose="edit"
            />
          </template>
        </Card>
      </div>

    </div>

  </div>
</template>

<script>

import Card from "@/view/content/Card.vue"
import Form from "@/component/inventory/StockOpnameFormEdit.vue";
import module from '@/core/modules/CrudModule.js'
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module"

export default {

  components: {
    Card,
    Form
  },

  data() {
    return {
      // Form
      form: {},
      // Other
      dataLoaded: false
    }
  },

  methods: {

    async get() {
      this.form = await module.get('inventory-stock-opname/' + this.$route.params.id)
      // If Data Not Found
      if (this.form == null) {
        // Redirect To List
        this.$router.push('/inventory/stock-opname')
      } else {
        this.form['_method'] = 'put'
        this.dataLoaded = true
      }
    }

  },

  mounted() {
    // BreadCrumb
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Inventori", route: "/inventory/list" },
      { title: "Stok Opname", route: "/inventory/stock-opname" },
      { title: "Edit" },
    ])
    // Get Data
    this.get()
  },

}

</script>